import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
const HomePage = React.lazy(() => import("./Pages/Home"));
const EnvIndex = React.lazy(() => import("./Pages/Env"));
function Routes() {

    return (
        <BrowserRouter>
            <Suspense fallback={<div style={{ color: "#000", textAlign: "center" }}>
                Loading ...
            </div>}>
                <Switch>

                    <Route path="/" component={HomePage} exact />
                    <Route path="/env" component={EnvIndex} />


                    <Route path="*" component={HomePage} />


                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
export default Routes;